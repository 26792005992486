import * as React from "react";
import { graphql } from "gatsby";

import { useLocale } from "@context/locale";

import Breadcrumbs from "@components/Breadcrumbs";
import Seo from "@components/Seo";

import SortingBlog from "@page_components/blog/SortingBlog";

function BlogCategoryPage({ data, pageContext }) {
  const { lang } = useLocale();
  const { posts, categories } = data;
  const { title } = pageContext;

  const blog_data = {
    posts: posts.nodes,
    categories: categories.nodes,
  };
  const breadcrumbs_data = [
    { name: "Blog", href: lang === "en" ? `/en/blog` : `/blog` },
    { name: title },
  ];

  return (
    <>
      <Seo
        title={`Blog - ${title} | Dream Factory`}
        description={`Wpisy - ${title}`}
      />
      <div className="blog-page">
        <div className="container">
          <Breadcrumbs data={breadcrumbs_data} />
          <main>
            <h1>Blog</h1>
          </main>
        </div>

        <section className="blog-page__list">
          <div className="container">
            <SortingBlog
              blog_data={blog_data}
              context={title}
              start_index={0}
            />
          </div>
        </section>
      </div>
    </>
  );
}

export default BlogCategoryPage;

export const query = graphql`
  query ($slug: String!, $lang: String!) {
    posts: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
    ) {
      nodes {
        title
        categories {
          nodes {
            name
            slug
          }
        }
        content
        excerpt
        date(formatString: "MM.DD.YYYY")
        slug
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            gatsbyImage(
              width: 690
              height: 410
              quality: 100
              layout: FULL_WIDTH
            )
            altText
          }
        }
      }
    }

    categories: allWpCategory(
      filter: {
        name: { nin: ["Wszystkie", "Bez kategorii"] }
        language: { slug: { eq: $lang } }
      }
    ) {
      nodes {
        slug
        name
      }
    }
  }
`;
